<template>
  <v-container fluid>
    <div class="custom-path-readiness">
      <div class="readiness-title">Readiness</div>
      <div class="custom-readiness">
        <ReadinessBar :barValue="100" :maxValue="150" type="readiness" />
      </div>
    </div>
    <div class="custom-path">
      <PathToReadinessTreeList
        :dataSource="dataSource"
        :topLevel="topLevel"
        :removeBorder="removeBorder"
        :dropdownItems="dropdownItems"
      />
    </div>
  </v-container>
</template>
<script>
import PathToReadinessTreeList from "@components/DataTable/PathToReadinessTreeList.vue";
import dropdownMixin from "@components/DropDownMenu/dropdownMixin";
import ReadinessBar from "@components/ReadinessBar/ReadinessBar.vue";
export default {
  name: "EmployeePathToReadinessView",
  components: {
    PathToReadinessTreeList,
    ReadinessBar,
  },
  data() {
    return {
      dataSource: [
        {
          id: 1,
          parentId: -1,
          data: {
            type: "position",
            commitmentLevel: "Acting as",
            assignmentType: "Position",
            importanceLevel: 2,
            importanceSelector: true,
            totalSteps: 3,
            code: "#9999",
            name: "Position Name",
            isNew: true,
            isAccepted: false,
          },
          readiness: 30,
        },
        {
          id: 2,
          parentId: 1,
          data: {
            type: "role",
            commitmentLevel: "Primary",
            assignmentType: "Role",
            importanceLevel: 3,
            importanceSelector: true,
            totalSteps: 3,
            code: "#9999",
            name: "Role Name",
            isNew: true,
            isAccepted: true,
          },
          readiness: 125,
        },
        {
          id: 3,
          parentId: 2,
          data: {
            type: "responsibility",
            commitmentLevel: "Primary",
            assignmentType: "Responsibility",
            importanceLevel: 1,
            importanceSelector: true,
            totalSteps: 3,
            code: "#9999",
            name: "Responsibility Name",
            isNew: true,
            isAccepted: true,
          },
          readiness: 40,
        },
        {
          id: 4,
          parentId: 2,
          data: {
            type: "responsibility",
            commitmentLevel: "Primary",
            assignmentType: "Responsibility",
            importanceLevel: 2,
            importanceSelector: true,
            totalSteps: 3,
            code: "#9999",
            name: "Responsibility Name",
            isNew: true,
            isAccepted: true,
          },
          readiness: 20,
        },
        {
          id: 5,
          parentId: -1,
          data: {
            type: "position",
            commitmentLevel: "Primary",
            assignmentType: "Position",
            importanceLevel: 3,
            importanceSelector: true,
            totalSteps: 3,
            code: "#9999",
            name: "Position Name",
            isNew: true,
            isAccepted: false,
          },
          readiness: 100,
        },
        {
          id: 6,
          parentId: 5,
          data: {
            type: "role",
            commitmentLevel: "Primary",
            assignmentType: "Role",
            importanceLevel: 2,
            importanceSelector: true,
            totalSteps: 3,
            code: "#9999",
            name: "Role Name",
            isNew: true,
            isAccepted: true,
          },
          readiness: 30,
        },
        {
          id: 7,
          parentId: 6,
          data: {
            type: "responsibility",
            commitmentLevel: "Primary",
            assignmentType: "Responsibility",
            importanceLevel: 1,
            importanceSelector: true,
            totalSteps: 3,
            code: "#9999",
            name: "Responsibility Name",
            isNew: true,
            isAccepted: true,
          },
          readiness: 10,
        },
        {
          id: 8,
          parentId: 6,
          data: {
            type: "responsibility",
            commitmentLevel: "Primary",
            assignmentType: "Responsibility",
            importanceLevel: 3,
            importanceSelector: true,
            totalSteps: 3,
            code: "#9999",
            name: "Responsibility Name",
            isNew: true,
            isAccepted: true,
          },
          readiness: 120,
        },
        {
          id: 9,
          parentId: 6,
          data: {
            type: "responsibility",
            commitmentLevel: "Primary",
            assignmentType: "Responsibility",
            importanceLevel: 1,
            importanceSelector: true,
            totalSteps: 3,
            code: "#9999",
            name: "Responsibility Name",
            isNew: true,
            isAccepted: true,
          },
          readiness: 0,
        },
        {
          id: 10,
          parentId: 6,
          data: {
            type: "responsibility",
            commitmentLevel: "Primary",
            assignmentType: "Responsibility",
            importanceLevel: 2,
            importanceSelector: true,
            totalSteps: 3,
            code: "#9999",
            name: "Responsibility Name",
            isNew: true,
            isAccepted: true,
          },
          readiness: 60,
        },
      ],
      topLevel: "position",
      removeBorder: false,
    };
  },
  mixins: [dropdownMixin],
  methods: {
    dropdownItems() {
      const items = [
        {
          name: "Activate",
          value: "activate_action",
          icon: "icons/dropdown/activate.svg",
          order: 1,
        },
        {
          name: "Delete",
          value: "delete_action",
          icon: "icons/dropdown/delete.svg",
          negative: true,
          order: 3,
        },
      ];
      return items;
    },
  },
};
</script>
<style>
.custom-path-readiness {
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.readiness-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #344054;
}
.custom-readiness {
  width: 100%;
  margin-left: 15px;
}
.custom-readiness .progress-bar-container {
  width: 100%;
}
.custom-readiness .progress-value {
  font-weight: 600;
}
.custom-path {
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
  padding: 15px;
}
</style>
