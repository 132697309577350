import {
  getListofResponsibility,
  createResponsibility,
  getListofResponsibilityBadge,
  createResponsibilityBadge,
  getListofEmployee,
  deleteResponsibilityBadge,
  responsibilityStateUpdate,
  deleteResponsibility,
  getResponsibilityId,
  getListofEmployeeOfResId,
  getResponsibilityChatter,
  getResponsibilityChecklist,
  createResponsibilityCheckpoint,
  deleteResponsibilityCheckpoint,
  getRoleId,
  checkpointStateUpdate,
  changeCheckpoint,
  getListActiveCheckpointsEval,
  getCheckpointChatterList,
  detachObject
} from "@/api";
import AddResponsibility from "@components/RolesCatalog/AddResponsibility.vue";
import ExResponsibilityModal from "@components/ResponsibilityModal/ExResponsibilityModal.vue";
import EmployeeModal from "@components/EmployeeModal/EmployeeModal.vue";
import ConfirmationDialog from "@components/RolesCatalog/Confirmation.vue";
import RenameResponsibility from "@components/RolesCatalog/RenameResponsibility.vue";
import DeleteResponsibility from "@components/RolesCatalog/DeleteResponsibility.vue";
import ActivateResponsibility from "@components/RolesCatalog/ActivateResponsibility.vue";
import RetireResponsibility from "@components/RolesCatalog/RetireResponsibility.vue";
import DeActivateResponsibility from "@components/RolesCatalog/DeActivateResponsibility.vue";
import ReActivateResponsibility from "@components/RolesCatalog/ReActivateResponsibility.vue";
import DetachResponsibilityDialog from "@components/DetachRes/DetachResponsibilityDialog.vue";

export default {
  components: {
    AddResponsibility,
    ExResponsibilityModal,
    EmployeeModal,
    ConfirmationDialog,
    RenameResponsibility,
    DeleteResponsibility,
    ActivateResponsibility,
    RetireResponsibility,
    DeActivateResponsibility,
    ReActivateResponsibility,
    DetachResponsibilityDialog
  },
  methods: {
    async loadResponsibilities() {
      try {
        const { data } = await getListofResponsibility(this.accountId, 1, 500);
        if (data && data.results) {
          this.dataSourceResponsibilities = data.results.map((el) => {
            const newEl = {};
            newEl.id = el.id;
            newEl.parentId = -1;
            newEl.status = el.status ? el.status : 'unknown'; // Safeguard status
            el.code = el.code ? el.code + '' : 'N/A'; // Fallback value for code
            newEl.data = {
              type: "responsibility",
              ...el
            };
            return newEl;
          });
        }
      } catch (error) {
        console.error("Failed to fetch responsibilities: ", error);
      }
    },
    async createResponsibility(name) {
      try {
        this.$loader.setAppLoading(true);
        const payload = {
          name: name,
          account: this.accountId,
        };
        await createResponsibility(this.accountId, payload);
        await this.loadDataSource();
        this.dataSource = this.dataSourceResponsibilities;
        this.closeDialog();
      } catch (e) {
        console.error("e: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    async renameResponsibility(newName) {
      try {
        this.$loader.setAppLoading(true);
        const type = this.currentResponsibility?.type;
        const id = this.currentResponsibility?.realId;
        if (id) {
          const payload = {
            name: newName,
            account: this.accountId,
          };
          await responsibilityStateUpdate(this.accountId, id, payload);
        }
        this.currentResponsibility = null;
        await this.loadDataSource();
        if (type === "roles") {
          this.dataSource = this.dataSourceRoles;
        } else {
          this.dataSource = this.dataSourceResponsibilities;
        }
        this.closeDialog();
      } catch (e) {
        console.error("error: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    async activateResponsibility() {
      try {
        this.$loader.setAppLoading(true);
        const type = this.currentResponsibility?.type;
        const id = this.currentResponsibility?.realId;
        const name = this.currentResponsibility?.data?.name;
        if (!id || !name) {
          throw new Error("Data missing error");
        }
        const payload = {
          status: "active",
          name: name,
          account: this.accountId,
        };
        await responsibilityStateUpdate(this.accountId, id, payload);
        await this.loadDataSource();
        this.dataSource = type === "roles" ? this.dataSourceRoles : this.dataSourceResponsibilities;
        this.closeDialog();
      } catch (e) {
        console.error("error: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    async retireResponsibility() {
      this.$loader.setAppLoading(true);
      try {
        const type = this.currentResponsibility?.type;
        const id = this.currentResponsibility?.realId;
        const name = this.currentResponsibility?.data?.name;
        if (!id || !name) {
          throw new Error("Data missing error");
        }
        const payload = {
          status: "retired",
          name: name,
          account: this.accountId,
        };
        await responsibilityStateUpdate(this.accountId, id, payload);
        await this.loadDataSource();
        this.dataSource = type === "roles" ? this.dataSourceRoles : this.dataSourceResponsibilities;
        this.closeDialog();
      } catch (e) {
        console.log("error: ", e);
      }
      this.$loader.setAppLoading(false);
    },
    async deleteResponsibility() {
      try {
        this.$loader.setAppLoading(true);
        const type = this.currentResponsibility?.type;
        const idRole = this.currentResponsibility.realId;
        await deleteResponsibility(this.accountId, idRole);
        this.actions.deleteResponsibilityDialog = false;
        this.currentResponsibility = null;
        await this.loadDataSource();
        this.dataSource = type === "roles" ? this.dataSourceRoles : this.dataSourceResponsibilities;

        const totalItemsAfterDeletion = this.paginationModel.total - 1;
        const totalPages = Math.ceil(totalItemsAfterDeletion / this.paginationModel.perPage);
        if (this.paginationModel.page > totalPages && totalPages > 0) {
          this.paginationModel.page = totalPages;
        } else if (totalPages === 0) {
          this.paginationModel.page = 1;
        }
        this.paginationModel.total = totalItemsAfterDeletion;
      } catch (e) {
        console.error("e: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    async loadResBadgeHolders(accountId, resId, type) {
      let data;
      try {
        if (type === "role") {
          const response = await getRoleId(accountId, resId);
          data = response.data;
        } else {
          const response = await getResponsibilityId(accountId, resId, type);
          data = response.data;
        }
        if (data) {
          const newEl = {};
          newEl.id = data.id;
          newEl.realId = data.id;
          newEl.parentId = -1;
          newEl.status = data.status ? data.status : 'unknown'; // Safeguard status
          data.code = this.getOrgCodeName(data);
          newEl.data = {
            type: "responsibility",
            ...data
          };
        newEl.type = "responsibility";
        newEl.types = "";
        let typeCountRes = [];
        if (data.attachments && data.attachments.length > 0) {
          typeCountRes = data.attachments.reduce((acc, att) => {
            acc[att.type] = (acc[att.type] || 0) + 1;
            return acc;
          }, {});
        }
        data.attachments = Object.entries(typeCountRes).map(
          ([type, quantity]) => ({ type, quantity })
        );
        let totalCount = 0;
        if (data?.attachments_count) {
          totalCount = Object.values(data.attachments_count).reduce(
            (sum, count) => sum + count,
            0
          );
        }
        newEl.attachedTo = {
          data: data.attachments,
        };
        newEl.assignment = totalCount;
          this.currentOrgResponsibility = newEl;
          this.currentResponse = newEl;
          this.modalHeaderState = {
            status: data.status,
            icon: type === "role" ? "org_icon_role" : "org_icon_responsibility",
            label: data.name,
            code: this.getOrgCodeName(data),
            name: "",
          };
          await this.getResponsiblityChecklist(data);
          await this.getResponsiblityBadges(data, type);
          await this.getResponsiblityChatter();
        }
      } catch (e) {
        console.error("Error fetching responsibility data: ", e);
      }
    },
    getOrgCodeName(data) {
      if (data?.code_str) {
        return data.code_str.split(" ")[0];
      } else {
        return "#" + data.code;
      }
    },
    async getResponsiblityBadges(res, type) {
        const data1 = await getListofEmployeeOfResId(this.accountId, 1, 500, res.id);
      if (data1.data && data1.data.results) {
          this.employeeData = data1.data.results.map((el) => {
            const newEl = {};
            newEl.id = el.id;
          newEl.fullName = el.first_name + ' ' + el.last_name;
            newEl.firstName = el.first_name;
            newEl.lastName = el.last_name;
            newEl.title = el.title;
            newEl.avatar = el.avatar;
            newEl.current_status = el.current_status;
            return newEl;
          });
        }
      const data2 = await this.$store.dispatch(
        "employeeStore/loadEmployeesFromServer"
      );
      if (data2 && data2.length > 0) {
        this.employeeFilterData = data2.map((el) => {
          const newEl = {};
          newEl.id = el.id;
          newEl.fullName = el.first_name + ' ' + el.last_name;
          newEl.firstName = el.first_name;
          newEl.lastName = el.last_name;
          newEl.title = el.title;
          newEl.avatar = el.avatar;
          return newEl;
        });
      }
      const { data } = await getListofResponsibilityBadge(this.accountId, res.id, type);
      if (data && data.results) {
        this.dataResponsibilitiesBadge = data.results;
      }
      const userMap = this.employeeFilterData.reduce((map, user) => {
        map[user.id] = user;
        return map;
      }, {});
      this.badgeFrameItems = this.dataResponsibilitiesBadge.map(badge => {
        const user = userMap[badge.granted_to];
        if (user) {
          let levelName = '';
          if (badge.level === 1) {
            levelName = 'Apprentice';
          } else if (badge.level === 2) {
            levelName = 'Professional';
          } else if (badge.level === 3) {
            levelName = 'Coach';
        } else {
            levelName = 'Master';
          }
          let statusName = '';
          if (badge.status === 'good_standing') {
            statusName = 'Good Standing';
          } else if (badge.status === 'forced') {
            statusName = 'Forced';
          } else {
            statusName = 'Obsolete';
          }
          return {
            id: badge.id,
            proficiencylevel: levelName,
            avatar: user.avatar,
            firstName: user.firstName,
            lastName: user.lastName,
            dot: statusName,
            empId: user.id,
            is_current: badge.is_current,
            expires_when_obsolete: badge.expires_when_obsolete,
            removed_by: badge.removed_by,
          };
        }
      }).filter(item => item !== undefined);
      this.menuItems[1].count = this.badgeFrameItems.filter(
          (el) => el?.removed_by === null
        ).length;
    },
    async addForceBadgeData(res) {
      const resId = this.currentResponse?.id
      try {
        const payload = {
          level: res.radioVal,
          granted_to: res.employee.id,
          remove_reason: res.reason,
          granted_for: resId,
        };
        await createResponsibilityBadge(this.accountId, resId, payload);
        this.showBadgeDialog = false;
        await this.getResponsiblityBadges(this.currentResponse, this.paramType);
      } catch (e) {
        console.error("Error adding badge: ", e);
        if (e.response && e.response.data && e.response.data.non_field_errors) {
          this.showBadgeDialog = false;
        }
      }
    },
    async confirmRemoveBadge() {
      try {
        const payload = {
          badge: this.badgeData.id,
          method: "string",
          reason: "string",
        };
        await deleteResponsibilityBadge(this.accountId, this.badgeData.id, payload);
        await this.getResponsiblityBadges(this.currentResponse, this.paramType);
        this.showConfirmDialog = false;
      } catch (e) {
        console.error("Error removing badge: ", e);
      }
    },
    async deActivateResponsibility() {
      try {
        this.$loader.setAppLoading(true);
        const type = this.currentResponsibility?.type;
        const id = this.currentResponsibility?.realId;
        const name = this.currentResponsibility?.data?.name;
        if (!id || !name) {
          throw "data missing error"
        }
        const payload = {
          status: "inactive",
          name: this.currentResponsibility?.data?.name,
          account: this.accountId,
        };
        await responsibilityStateUpdate(this.accountId, id, payload);
        await this.loadDataSource();
        if (type === "roles") {
          this.dataSource = this.dataSourceRoles;
        } else {
          this.dataSource = this.dataSourceResponsibilities;
        }
        this.closeDialog();
      } catch (e) {
        console.error("error: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    async reActivateResponsibility() {
      this.$loader.setAppLoading(true);
      try {
        const type = this.currentResponsibility?.type;
        const id = this.currentResponsibility?.realId;
        const name = this.currentResponsibility?.data?.name;
        if (!id || !name) {
          throw "data missing error"
        }
        const payload = {
          status: "active",
          name: this.currentResponsibility?.data?.name,
          account: this.accountId,
        };
        await responsibilityStateUpdate(this.accountId, id, payload);
        await this.loadDataSource();
        if (type === "roles") {
          this.dataSource = this.dataSourceRoles;
        } else {
          this.dataSource = this.dataSourceResponsibilities;
        }
        this.closeDialog();
      } catch (e) {
        console.error("error: ", e);
      }
      this.$loader.setAppLoading(false);
    },
    async getResponsiblityChatter() {
      const page = Math.ceil(this.resChatter.length / 10);
        const { data } = await getResponsibilityChatter(this.accountId, this.currentResponse.id, page + 1);
        if (data && data.count) {
          this.totalChatterData = data.count;
        }
        if (data && data.results) {
          let chatterItems = data.results.map((el) => {
            const date = new Date(el.recorded_on);
            const milliseconds = date.getTime();
            const millisecondsString = milliseconds.toString();
            let nameParts = el.recorded_by_name.split(" ");
            const newEl = {};
            newEl.id = el.id;
            if (nameParts.length === 2) {
              newEl.avatar = {
                picture: "",
                firstName: nameParts[0],
                lastName: nameParts[1],
              };
            } else {
              newEl.avatar = {
                picture: "",
                firstName: el.recorded_by_name,
                lastName: "",
              };
            }
            newEl.IsExIQtiveBot = false;
            newEl.message = el.note;
            newEl.created_on = millisecondsString;
            newEl.IsHistory = el.is_history;
            return newEl;
          });
          this.resChatter.push(...chatterItems);
        }
    },
    async getResponsiblityChecklist(res) {
      const data1 = await getResponsibilityChecklist(this.accountId, res.id);
      if (data1.data && data1.data.results) {
        this.checklistTask = data1.data.results.map((el) => {
          let levelName = '';
          if (el.proficiency_level === 1) {
            levelName = 'Apprentice';
          } else if (el.proficiency_level === 2) {
            levelName = 'Professional';
          } else if (el.proficiency_level === 3) {
            levelName = 'Coach';
          } else {
            levelName = 'Master';
          }
          const newEl = {};
          newEl.id = el.id;
          newEl.level = levelName;
          newEl.criteria = el.criteria;
          newEl.instructions = el.instructions;
          newEl.status = el.status;
          return newEl;
        });
        this.menuItems[0].count = this.checklistTask.filter(
          (el) => el?.status && el?.status == "active"
        ).length;
      }
    },
    async checkFormData(data) {
      try {
        let levelName = "";
        if (data.level === "Apprentice") {
          levelName = 1;
        } else if (data.level === "Professional") {
          levelName = 2;
        } else if (data.level === "Coach") {
          levelName = 3;
        } else {
          levelName = 4;
        }
        const payload = {
          level: levelName,
          criteria: data.criteria,
          instructions: data.instructions,
        };
        await createResponsibilityCheckpoint(
          this.accountId,
          this.currentResponse?.id,
          payload
        );
        this.getResponsiblityChecklist(this.currentResponse);
      } catch (e) {
        console.error("Error adding checkpoint: ", e);
      }
    },
    async deleteCheckpoint(id) {
      this.$loader.setAppLoading(true);
      const account_id = JSON.parse(localStorage.getItem("currentAccountID"));
      const res_id = this.getResponsibilityId(this.$route.path);
      try {
        if (id) {
          await deleteResponsibilityCheckpoint(account_id, res_id, id);
          const data = {
            id: res_id
          };
          await this.getResponsiblityChecklist(data);
        }
      } catch (e) {
        console.log("Error deleting checkpoint: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
      this.showDeleteDialog = false;
    },
    getResponsibilityId(route) {
      const currnetRoute = route;
      const parts = currnetRoute.split('/');
      return parts[parts.length - 1];
    },
    async activateCheckpoint() {
      try {
        this.$loader.setAppLoading(true);
        const id = this.currentCheckPoint?.id;
        const res_id = this.currentResponse?.id;
        if (!id) {
          throw new Error("Data missing error");
        }
        await checkpointStateUpdate(this.accountId, res_id, id, 'activate');
        this.getResponsiblityChecklist(this.currentResponse);
        this.showActivateCheckpointDialog = false;
      } catch (e) {
        console.error("Error activating checkpoint: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    async retireCheckpoint() {
      try {
        this.$loader.setAppLoading(true);
        const id = this.currentCheckPoint?.id;
        const res_id = this.currentResponse?.id;
        if (!id) {
          throw new Error("Data missing error");
        }
        await checkpointStateUpdate(this.accountId, res_id, id, 'retire');
        this.getResponsiblityChecklist(this.currentResponse);
        this.showRetireCheckpointDialog = false;
      } catch (e) {
        console.error("Error retiring checkpoint: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    async reActivateCheckpoint() {
      try {
        this.$loader.setAppLoading(true);
        const id = this.currentCheckPoint?.id;
        const res_id = this.currentResponse?.id;
        if (!id) {
          throw new Error("Data missing error");
        }
        await checkpointStateUpdate(this.accountId, res_id, id, 'reactivate');
        this.getResponsiblityChecklist(this.currentResponse);
        this.showReActivateCheckpointDialog = false;
      } catch (e) {
        console.error("Error reactivating checkpoint: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    async changeCheckpoint(data) {
      try {
        const id = this.currentCheckPoint?.id;
        const res_id = this.currentResponse?.id;
        if (!id) {
          throw new Error("Data missing error");
        }
        await changeCheckpoint(this.accountId, res_id, id, data);
        this.getResponsiblityChecklist(this.currentResponse);
      } catch (e) {
        console.error("Error changing checkpoint: ", e);
      }
    },
    async openEvalResponse(data) {
      this.showBadgeDialog = false;
      this.openEvalResponsibility = true;
      this.evalModalHeaderState = {
        responsibilityCode: "#" + this.currentResponse.data.code,
        responsibilityName: this.currentResponse.data.name,
        roleCode: "#245",
        roleName: "Implement CAPEX initiatives",
        positionCode: "#4567",
        positionName: "General Manager",
      };
      this.evalModalEmpState = {
        id: data.employee.id,
        firstName: data.employee.firstName,
        lastName: data.employee.lastName,
        position: data.employee.title,
        photoUrl: data.employee.avatar,
        availability: 1,
        isAllocated: false,
      };
      this.loadListActiveCheckpointsEval(data);
    },
    async loadListActiveCheckpointsEval(data){
     const data3 =  await getListActiveCheckpointsEval(this.accountId, this.currentResponse.id, data.employee.id);
      if (data3 && data3.data) {
        this.checkpointsData = data3.data.map((el) => {
          const newEl = {};
          newEl.id = el.id;
          newEl.status = el.id;
          newEl.level = el.proficiency_level;
          newEl.level_name = this.getProficiencyLevelNameFromNumber(el);
          newEl.criteria = el.criteria;
          newEl.instructions = el.instructions;
          newEl.status = el.status;
          newEl.evaluation_status = el.evaluation_status;
          newEl.change_evaluation_status = this.updateChangeEvaluationStatus(el);
          newEl.failed_by = "Test user";
          newEl.failed_on = "Test user";
          newEl.failed_reason = "Lorem ipsum dolor sit amet, consectetur.";
          newEl.reset_by = "Test user";
          newEl.reset_on = "Test user";
          newEl.reset_reason = "Lorem ipsum dolor sit amet.";
          newEl.avatar = "https://www.shareicon.net/data/512x512/2016/09/15/829472_man_512x512.png";
          return newEl;
        });
      }
      return this.checkpointsData;
    },
    getProficiencyLevelNameFromNumber(el){
      let levelName = '';
      if (el.proficiency_level === 1) {
        levelName = 'Apprentice';
      } else if (el.proficiency_level === 2) {
        levelName = 'Professional';
      } else if (el.proficiency_level === 3) {
        levelName = 'Coach';
      } else {
        levelName = 'Master';
      }
      return levelName;
    },
    updateChangeEvaluationStatus(data){
       if(data.evaluation_status == "not_applicable"){
          return "Not Applicable";
       } else if(data.evaluation_status == "passed"){
          return  "Passed";
       } else if(data.evaluation_status == "failed"){
          return  "Failed";
       } else {
        return  "Reset Pending Evaluation";
       }

    },
    async getChatterList(checkpoint, page) {
      if (page === 1) {
        this.chatterList = [];
      }
      const { data } = await getCheckpointChatterList(this.accountId, checkpoint.id,  page);
      if (data && data.count) {
        this.totalCheckpointChatter = data.count;
      }
      if (data && data.results) {
        let chatterItems = data.results.map((el) => {
          const date = new Date(el.recorded_on);
          const milliseconds = date.getTime();
          const millisecondsString = milliseconds.toString();
          let nameParts = el.recorded_by_name.split(" ");
          const newEl = {};
          newEl.id = el.id;
          if (nameParts.length === 2) {
            newEl.avatar = {
              picture: "",
              firstName: nameParts[0],
              lastName: nameParts[1],
            };
          } else {
            newEl.avatar = {
              picture: "",
              firstName: el.recorded_by_name,
              lastName: "",
            };
          }
          newEl.IsExIQtiveBot = false;
          newEl.message = this.truncateHtml(el.note);
          newEl.created_on = millisecondsString;
          newEl.IsHistory = el.is_history;
          return newEl;
        });
        this.chatterList.push(...chatterItems);
      }
    },
    truncateHtml(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      let text = div.textContent || div.innerText || '';
      return text;
    },
    async detachResponsibility () {
      try {
        const payload = {
          parent_id: this.currentResponsibility.parentId,
        };
        await detachObject(
          this.accountId,
          this.currentResponsibility.data.type,
          this.currentResponsibility.data.id,
          payload
        );
        this.closeDialog();
        await this.loadDataSource();
      } catch (e) {
        console.log("e: ", e);
      }
    }
  },
};
