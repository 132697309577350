var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position_capacity_icon",class:{
    'capacity_icon_normal_size': _vm.size === 'normal',
    'capacity_icon_small_size': _vm.size === 'small'
  }},[_c('div',{staticClass:"circle",class:{
      bg_transparent: _vm.value === 1 && _vm.occupied === 'not',
      bg_gray_transparent: _vm.value === 1 && _vm.occupied === '0.5',
      bg_gray_gray: _vm.value === 1 && _vm.occupied === '2x0.5',
      bg_gray: _vm.value === 1 && _vm.occupied === '1',
      bg_black_transparent: _vm.value === 0.5 && _vm.occupied === 'not',
      bg_black_gray: _vm.value === 0.5 && _vm.occupied === '0.5',
      position_icon_br_normal: _vm.size === 'normal',
      position_icon_br_small: _vm.size === 'small',
    }}),_c('div',{staticClass:"position_capacity_icon_wrapper",class:{
      'normal_icon_size': _vm.size === 'normal',
      'small_icon_size': _vm.size === 'small'
    }})])
}
var staticRenderFns = []

export { render, staticRenderFns }