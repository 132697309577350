var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.nameLink)?_c('div',{class:{
  'd-flex org-object-card': true,
  'clickable': _vm.nameLink
},on:{"click":_vm.openLink}},[(_vm.showIcon)?_c('span',{staticClass:"org-object-icon-container mr-2",attrs:{"data-testid":"org-object-icon-container"}},[_c('Icon',{attrs:{"name":'org_icon_' + _vm.icon}})],1):_vm._e(),(_vm.showCode)?_c('span',{staticClass:"object-card-code mr-4",attrs:{"data-testid":"obj-card-code"}},[_vm._v(_vm._s(_vm.code))]):_vm._e(),_c('span',{class:{
    'object-card-name': true,
    'underlined': (!_vm.nameLink && !_vm.removeUnderlineToName)
  },attrs:{"data-testid":"obj-card-name"}},[_vm._v(_vm._s(_vm.name))])]):_c('router-link',{class:{
    'd-flex org-object-card': true,
    'clickable': _vm.nameLink
  },attrs:{"to":_vm.nameLink}},[(_vm.showIcon)?_c('span',{staticClass:"org-object-icon-container mr-2",attrs:{"data-testid":"org-object-icon-container"}},[_c('Icon',{attrs:{"name":'org_icon_' + _vm.icon}})],1):_vm._e(),(_vm.showCode)?_c('span',{staticClass:"object-card-code mr-4",attrs:{"data-testid":"obj-card-code"}},[_vm._v(_vm._s(_vm.code))]):_vm._e(),_c('span',{class:{
      'object-card-name': true,
      'underlined': (_vm.nameLink && !_vm.removeUnderlineToName)
    },attrs:{"data-testid":"obj-card-name"}},[_vm._v(" "+_vm._s(_vm.name)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }