<template>
  <NotFound :goBack="goBack" />
</template>
<script>
import NotFound from "@components/PageNotFound/NotFound.vue";

export default {
  async mounted() {
    await this.loadAppCount();
  },
  components: {
    NotFound,
  },
  methods: {
    async loadAppCount() {
      await this.$store.dispatch("employeeStore/loadEmployees");
      await this.$store.dispatch("positionStore/loadPositions");
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>
