import { render, staticRenderFns } from "./PositionCard.vue?vue&type=template&id=21e71912&scoped=true"
import script from "./PositionCard.vue?vue&type=script&lang=js"
export * from "./PositionCard.vue?vue&type=script&lang=js"
import style0 from "./PositionCard.vue?vue&type=style&index=0&id=21e71912&prod&scoped=true&lang=css"
import style1 from "./PositionCard.vue?vue&type=style&index=1&id=21e71912&prod&lang=css"


/* normalize component */
import normalizer from "!../../../account/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e71912",
  null
  
)

export default component.exports