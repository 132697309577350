import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "@/views/Login.vue";
import AdminView from "@/views/AdminView.vue";
import EmployeeView from "@/views/EmployeeView.vue";
import PositionView from "@/views/PositionView.vue";
import SettingView from "@/views/SettingView.vue";
import MyStuffView from "@/views/MyStuffView.vue";
import ReadinessCatalogsView from "@/views/ReadinessCatalogsView.vue";
import ReadinessEmployeeView from "@/views/ReadinessEmployeeView.vue";
import ResBadgeHolderView from "@/views/ResBadgeHolderView.vue";
import OrginationPositionView from "@/views/OrginationPositionView.vue";
import EmployeePathToReadinessView from "@/views/DetailEmployeeViews/EmployeePathToReadinessView.vue";
import DetailEmployeeView from "@/views/DetailEmployeeView.vue";
import EmployeeDetailDashboardView from "@/views/DetailEmployeeViews/EmployeeDetailDashboardView.vue";
import RolePageView from "@/views/RolePageView.vue";
import BeMasterCoachView from "@/views/BeMasterCoachView.vue";
import EmployeeChatterView from "@/views/DetailEmployeeViews/EmployeeChatterView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import NotificationsListView from "@components/views/NotificationsListView.vue";
import NotFoundView from "@/views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: LoginView,
    meta: { hideMainSidebar: true },
  },
  {
    path: "/organization/employees",
    name: "Employees",
    component: EmployeeView,
  },
  {
    path: "/organization/employees/:id",
    name: "DetailEmployee",
    component: DetailEmployeeView,
    meta: { secondSidebar: "employee" },
    children: [
      {
        path: "/organization/employees/:id/dashboard",
        name: "DetailEmployeeDashboard",
        component: EmployeeDetailDashboardView,
        meta: { secondSidebar: "employee" },
      },
      {
        path: "/organization/employees/:id/pathtoreadiness",
        name: "EmployeePathToReadiness",
        component: EmployeePathToReadinessView,
        meta: { secondSidebar: "employee" },
      },
      {
        path: "/organization/employees/:id/chatter",
        name: "EmployeePathToReadiness",
        component: EmployeeChatterView,
        meta: { secondSidebar: "employee" },
      },
    ],
  },
  {
    path: "/organization/positions",
    name: "Positions",
    component: PositionView,
  },
  {
    path: "/admin",
    name: "Admin",
    component: AdminView,
    meta: { sideBarPresent: false, role: "admin" },
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingView,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPasswordView,
    meta: { hideMainSidebar: true },
  },
  {
    path: "/setup/password",
    name: "SetNewPassword",
    component: ForgotPasswordView,
    meta: { hideMainSidebar: true },
  },
  {
    path: "/reset/password",
    name: "SetNewPasswordReset",
    component: ForgotPasswordView,
    meta: { hideMainSidebar: true },
  },
  {
    path: "/services/my-stuff",
    name: "MyStuff",
    component: MyStuffView,
  },
  {
    path: "/readiness/catalogs",
    name: "ReadinessCatalogs",
    component: ReadinessCatalogsView,
  },
  {
    path: "/readiness/catalogs/:type",
    name: "ReadinessCatalogsDetail",
    component: ReadinessCatalogsView,
  },
  {
    path: "/readiness/catalogs/responsibilities/:objId",
    name: "ReadinessCatalogsDetailObjId",
    component: ResBadgeHolderView,
  },
  {
    path: "/readiness/catalogs/roles/:objId",
    name: "ReadinessCatalogsDetailObjId",
    component: RolePageView,
  },
  {
    path: "/readiness/employees",
    name: "ReadinessEmployees",
    component: ReadinessEmployeeView,
  },
  {
    path: "/readiness/responsibility/holder/:resId",
    name: "ResBadgeHolder",
    component: ResBadgeHolderView,
  },
  {
    path: "/organization/positions/position/:pId",
    name: "OrgPosition",
    component: OrginationPositionView,
  },
  {
    path: "/readiness/catalogs/roles/:type/:objId",
    name: "ReadinessCatalogsRolesResponse",
    component: ResBadgeHolderView,
  },
  {
    path: "/organization/positions/:pId/:type/:objId",
    name: "OrgPositionDetailObjId",
    component: RolePageView,
  },
  {
    path: "/organization/positions/:pId/roles/:type/:objId",
    name: "OrgPositionRolesResponse",
    component: ResBadgeHolderView,
  },
  {
    path: "/services/be-master-coach",
    name: "BeMasterCoach",
    component: BeMasterCoachView,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: NotificationsListView,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/forgot-password",
    "/setup/password",
    "/reset/password",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn =
    localStorage.getItem("token") && localStorage.getItem("currentAccountID");

  if (authRequired && !loggedIn) {
    if (to.fullPath == "/") {
      return next("/login");
    } else {
      localStorage.clear();
      return next({ path: "/login", query: { nextUrl: to.fullPath } });
    }
  }
  if (!authRequired) {
    localStorage.clear();
  }
  next();
});

export default router;
