import axios from "axios";
import router from "../router";

/**
 * Create Axios Instance for API Call
 *
 * @param {boolean} withToken
 * @param { { headers: Object, baseURL: string } } options
 */
export const createAxiosInstance = (withToken = true, options = {}) => {
  const { headers = {}, baseURL = `${process.env.VUE_APP_BASE_URL}` } = options;

  const instanceConfig = {
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
      ...(withToken
        ? { Authorization: "Token " + localStorage.getItem("token") }
        : {}),
      ...headers,
    },
  };

  const axiosInstance = axios.create(instanceConfig);
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        if (router.currentRoute.path != "/login") {
          router.push({
            path: "/login",
          });
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default createAxiosInstance;
