import {
  getListOfRoles,
  createRole,
  updateRole,
  attachResponsibilityToRole,
  createResponsibility,
  updateRetiredRole,
  deleteReadinessRole,
  updateDeActiveRole,
  updateReActiveRole,
  getPositionId,
  detachOrgObject,
  detachAttachments,
  detachRoleAttachment,
} from "@/api";
import AddRole from "@components/RolesCatalog/AddRole.vue";
import ActivateRole from "@components/RolesCatalog/ActivateRole.vue";
import AttachedRole from "@components/RolesCatalog/AttachedRole.vue";
import RetiredRole from "@components/RolesCatalog/RetiredRole.vue";
import DeleteRole from "@components/RolesCatalog/DeleteRole.vue";
import RenameRole from "@components/RolesCatalog/RenameRole.vue";
import DeActivateRole from "@components/RolesCatalog/DeActivateRole.vue";
import ReActivateRole from "@components/RolesCatalog/ReActivateRole.vue";
import DetachDialog from "@components/DetachDialog/DetachDialog.vue";
export default {
  components: {
    AddRole,
    ActivateRole,
    DeleteRole,
    RenameRole,
    AttachedRole,
    RetiredRole,
    DeActivateRole,
    ReActivateRole,
    DetachDialog,
  },
  data() {
    return {
      detachLoader: false, // Initialize the loader state
    };
  },
  computed: {
    isLoading() {
      return this.detachLoader;
    },
    storeRoles() {
      return this.$store.state.readinessCatalogsStore.roles;
    },
    storeResponsibilities() {
      return this.$store.state.readinessCatalogsStore.responsibilities;
    },
    sanitizeDataSource() {
      try {
        let rolesArray = [];
        if (this.storeRoles && this.storeRoles.length > 0) {
          rolesArray = this.storeRoles.map((el) => {
            let typeCountMap = [];
            if (el.attachments && el.attachments.length > 0) {
              typeCountMap = el.attachments.reduce((acc, att) => {
                acc[att.type] = (acc[att.type] || 0) + 1;
                return acc;
              }, {});
            }
            el.attachmentss = Object.entries(typeCountMap).map(
              ([type, quantity]) => ({ type, quantity })
            );

            let totalCount = 0;
            if (el.attachments_count) {
              totalCount = Object.values(el.attachments_count).reduce(
                (sum, count) => sum + count,
                0
              );
            }
            return {
              id: el.id,
              parentId: -1,
              status: el.status,
              data: {
                type: "role",
                code: el.code + "",
                name: el.name,
                status: el.status,
              },
              info: {
                ...el,
              },
              attachedTo: {
                data: el.attachmentss,
              },
              attachedToItems: {
                data: el.attachments,
              },
              assignment: totalCount,
              types: "",
            };
          });
        }
        const itemsToAdd = [];

        let responsibilitiesCopy = [];

        if (
          this.storeResponsibilities &&
          this.storeResponsibilities.length > 0
        ) {
          responsibilitiesCopy = this.storeResponsibilities.map((el) => {
            const newEl = {};
            newEl.id = el.id;
            newEl.realId = el.id;
            newEl.parentId = -1;
            newEl.status = el.status;
            el.code = el.code + "";
            newEl.data = {
              type: "responsibility",
              ...el,
            };
            newEl.type = "responsibility";
            newEl.types = "";
            let typeCountRes = [];
            if (el.attachments && el.attachments.length > 0) {
              typeCountRes = el.attachments.reduce((acc, att) => {
                acc[att.type] = (acc[att.type] || 0) + 1;
                return acc;
              }, {});
            }
            el.attachments = Object.entries(typeCountRes).map(
              ([type, quantity]) => ({ type, quantity })
            );
            let totalCount = 0;
            if (el?.attachments_count) {
              totalCount = Object.values(el.attachments_count).reduce(
                (sum, count) => sum + count,
                0
              );
            }
            newEl.attachedTo = {
              data: el.attachments,
            };
            newEl.attachedToItems = {
              data: el.attachments,
            };
            newEl.assignment = totalCount;
            return newEl;
          });
        }
        rolesArray.forEach((el) => {
          const responsibilitiesAttachments =
            el.info.attachments?.filter((ra) => ra.type == "responsibility") ||
            [];
          responsibilitiesAttachments.forEach((el2) => {
            const tempItem = responsibilitiesCopy.find(
              (res) => res.id == el2.id
            );
            if (tempItem) {
              const tempItemCopy = JSON.parse(JSON.stringify(tempItem));
              if (tempItemCopy) {
                tempItemCopy.id = itemsToAdd.length + 500;
                tempItemCopy.realId = tempItem.id;
                tempItemCopy.parentId = el.id;
                tempItemCopy.type = "roles";
                itemsToAdd.push(tempItemCopy);
              }
            }
          });
        });
        const finalArray = JSON.parse(
          JSON.stringify(rolesArray.concat(itemsToAdd))
        );
        let routeParams = this.$route?.params?.type || "roles";
        if (routeParams == "responsibilities") {
          if (this.dataSource.length > 0) {
            return this.applyFiltersToDataSource(this.dataSource);
          } else {
            return this.applyFiltersToDataSource(
              JSON.parse(JSON.stringify(responsibilitiesCopy))
            );
          }
        } else {
          if (this.dataSource.length > 0) {
            return this.applyFiltersToDataSource(this.dataSource);
          } else {
            return this.applyFiltersToDataSource(finalArray);
          }
        }
      } catch (error) {
        console.error("Error loading roles: ", error);
      }
    },
    originalDataSource() {
      try {
        let rolesArray = [];
        if (this.storeRoles && this.storeRoles.length > 0) {
          rolesArray = this.storeRoles.map((el) => {
            let typeCountMap = [];
            if (el.attachments && el.attachments.length > 0) {
              typeCountMap = el.attachments.reduce((acc, att) => {
                acc[att.type] = (acc[att.type] || 0) + 1;
                return acc;
              }, {});
            }
            el.attachmentss = Object.entries(typeCountMap).map(
              ([type, quantity]) => ({ type, quantity })
            );

            let totalCount = 0;
            if (el.attachments_count) {
              totalCount = Object.values(el.attachments_count).reduce(
                (sum, count) => sum + count,
                0
              );
            }
            return {
              id: el.id,
              parentId: -1,
              status: el.status,
              data: {
                type: "role",
                code: el.code + "",
                name: el.name,
                status: el?.status,
              },
              info: {
                ...el,
              },
              attachedTo: {
                data: el.attachmentss,
              },
              assignment: totalCount,
              types: "",
            };
          });
        }
        const itemsToAdd = [];

        let responsibilitiesCopy = [];

        if (
          this.storeResponsibilities &&
          this.storeResponsibilities.length > 0
        ) {
          responsibilitiesCopy = this.storeResponsibilities.map((el) => {
            const newEl = {};
            newEl.id = el.id;
            newEl.realId = el.id;
            newEl.parentId = -1;
            newEl.status = el.status;
            el.code = el.code + "";
            newEl.data = {
              type: "responsibility",
              ...el,
            };
            newEl.type = "responsibility";
            newEl.types = "";
            let typeCountRes = [];
            if (el.attachments && el.attachments.length > 0) {
              typeCountRes = el.attachments.reduce((acc, att) => {
                acc[att.type] = (acc[att.type] || 0) + 1;
                return acc;
              }, {});
            }
            el.attachments = Object.entries(typeCountRes).map(
              ([type, quantity]) => ({ type, quantity })
            );
            let totalCount = 0;
            if (el?.attachments_count) {
              totalCount = Object.values(el.attachments_count).reduce(
                (sum, count) => sum + count,
                0
              );
            }
            newEl.attachedTo = {
              data: el.attachments,
            };
            newEl.assignment = totalCount;
            return newEl;
          });
        }
        rolesArray.forEach((el) => {
          const responsibilitiesAttachments =
            el.info.attachments?.filter((ra) => ra.type == "responsibility") ||
            [];
          responsibilitiesAttachments.forEach((el2) => {
            const tempItem = responsibilitiesCopy.find(
              (res) => res.id == el2.id
            );
            if (tempItem) {
              const tempItemCopy = JSON.parse(JSON.stringify(tempItem));
              if (tempItemCopy) {
                tempItemCopy.id = itemsToAdd.length + 500;
                tempItemCopy.realId = tempItem.id;
                tempItemCopy.parentId = el.id;
                tempItemCopy.type = "roles";
                itemsToAdd.push(tempItemCopy);
              }
            }
          });
        });
        const finalArray = JSON.parse(
          JSON.stringify(rolesArray.concat(itemsToAdd))
        );
        const routeParams = this.$route.params.type;
        if (routeParams == "responsibilities") {
          return JSON.parse(JSON.stringify(responsibilitiesCopy));
        } else {
          return finalArray;
        }
      } catch (error) {
        console.error("Error loading roles: ", error);
      }
    },
  },
  methods: {
    async loadNewDataSource() {
      await this.$store.dispatch("readinessCatalogsStore/loadRoles");
      await this.$store.dispatch("readinessCatalogsStore/loadResponsibilities");
    },
    async loadDataSource() {
      try {
        const roleList = await this.$store.dispatch(
          "readinessCatalogsStore/loadRoles"
        );
        let rolesArray = [];
        if (roleList && roleList.length > 0) {
          rolesArray = roleList.map((el) => {
            let typeCountMap = [];
            if (el.attachments && el.attachments.length > 0) {
              typeCountMap = el.attachments.reduce((acc, att) => {
                acc[att.type] = (acc[att.type] || 0) + 1;
                return acc;
              }, {});
            }
            el.attachmentss = Object.entries(typeCountMap).map(
              ([type, quantity]) => ({ type, quantity })
            );

            let totalCount = 0;
            if (el.attachments_count) {
              totalCount = Object.values(el.attachments_count).reduce(
                (sum, count) => sum + count,
                0
              );
            }
            return {
              id: el.id,
              parentId: -1,
              status: el.status,
              data: {
                type: "role",
                code: el.code + "",
                name: el.name,
                status: el?.status,
              },
              info: {
                ...el,
              },
              attachedTo: {
                data: el.attachmentss,
              },
              attachedToItems: {
                data: el.attachmentss,
              },
              dashboard: {
                data: {
                  countApprentice: 0,
                  countProfessional: 0,
                  countCoach: 0,
                },
              },
              assignment: totalCount,
              types: "",
            };
          });
        }
        const itemsToAdd = [];

        const responsibilities = await this.$store.dispatch(
          "readinessCatalogsStore/loadResponsibilities"
        );

        let responsibilitiesCopy = [];

        if (responsibilities && responsibilities.length > 0) {
          responsibilitiesCopy = responsibilities.map((el) => {
            const newEl = {};
            newEl.id = el.id;
            newEl.realId = el.id;
            newEl.parentId = -1;
            newEl.status = el.status;
            el.code = el.code + "";
            newEl.data = {
              type: "responsibility",
              ...el,
            };
            newEl.type = "responsibility";
            newEl.types = "";
            newEl.dashboard = {
              data: {
                countApprentice: 0,
                countProfessional: 0,
                countCoach: 0,
              },
            };
            let typeCountRes = [];
            if (el.attachments && el.attachments.length > 0) {
              typeCountRes = el.attachments.reduce((acc, att) => {
                acc[att.type] = (acc[att.type] || 0) + 1;
                return acc;
              }, {});
            }
            el.attachments = Object.entries(typeCountRes).map(
              ([type, quantity]) => ({ type, quantity })
            );
            let totalCount = 0;
            if (el?.attachments_count) {
              totalCount = Object.values(el.attachments_count).reduce(
                (sum, count) => sum + count,
                0
              );
            }
            newEl.attachedTo = {
              data: el.attachments,
            };
            newEl.assignment = totalCount;
            return newEl;
          });
        }
        rolesArray.forEach((el) => {
          const responsibilitiesAttachments =
            el.info.attachments?.filter((ra) => ra.type == "responsibility") ||
            [];
          responsibilitiesAttachments.forEach((el2) => {
            const tempItem = responsibilitiesCopy.find(
              (res) => res.id == el2.id
            );
            if (tempItem) {
              const tempItemCopy = JSON.parse(JSON.stringify(tempItem));
              if (tempItemCopy) {
                tempItemCopy.id = itemsToAdd.length + 500;
                tempItemCopy.realId = tempItem.id;
                tempItemCopy.parentId = el.id;
                tempItemCopy.type = "roles";
                itemsToAdd.push(tempItemCopy);
              }
            }
          });
        });
        const finalArray = JSON.parse(
          JSON.stringify(rolesArray.concat(itemsToAdd))
        );
        this.dataSourceRoles?.splice(
          0,
          this.dataSourceRoles.length,
          ...finalArray
        );
        this.dataSourceResponsibilities = JSON.parse(
          JSON.stringify(responsibilitiesCopy)
        );
      } catch (error) {
        console.error("Error loading roles: ", error);
      }
    },
    applyFiltersToDataSource(dataSource) {
      if (this.appliedFilters && this.appliedFilters.length > 0) {
        return dataSource.filter((item) =>
          this.appliedFilters.includes(item.status)
        );
      }
      return dataSource;
    },
    async saveNewRole(roleName) {
      try {
        this.$loader.setAppLoading(true);
        const payload = {
          name: roleName,
          account: this.accountId,
        };
        await createRole(this.accountId, payload);
        await this.loadNewDataSource();
        const totalCount = this.sanitizeDataSource.filter(
          (el) => el.parentId == -1
        );
        this.paginationModel.total = totalCount.length;
        this.closeDialog();
      } catch (e) {
        console.log("e: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    async activateRole() {
      try {
        this.$loader.setAppLoading(true);
        const idRole = this.currentRole.id;
        const nameRole = this.currentRole.data.name;
        const payload = {
          name: nameRole,
          status: "active",
          account: this.accountId,
        };
        await updateRole(this.accountId, idRole, payload);
        this.actions.activateRoleDialog = false;
        this.currentRole = null;
        await this.loadDataSource();
        this.dataSource = this.dataSourceRoles;
      } catch (e) {
        console.log("e: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    async attachedRole() {
      try {
        this.$loader.setAppLoading(true);
        const objId = this.currentRole.id;
        const payload = {
          attachment_id: this.attachmentId,
        };
        await attachResponsibilityToRole(this.accountId, objId, payload);
        this.actions.attachedRoleDialog = false;
        this.currentRole = null;
        await this.loadDataSource();
        this.dataSource = this.dataSourceRoles;
      } catch (e) {
        console.log("e: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    async retiredRole() {
      this.$loader.setAppLoading(true);
      try {
        const idRole = this.currentRole.id;
        const nameRole = this.currentRole.data.name;
        const payload = {
          name: nameRole,
          status: "retired",
          account: this.accountId,
        };
        await updateRetiredRole(this.accountId, idRole, payload);
        this.actions.retiredRoleDialog = false;
        this.currentRole = null;
        await this.loadDataSource();
        this.dataSource = this.dataSourceRoles;
      } catch (e) {
        console.log("e: ", e);
      }
      this.$loader.setAppLoading(false);
    },
    async renameRole(newName) {
      try {
        this.$loader.setAppLoading(true);
        const idRole = this.currentRole?.id;
        if (!idRole) {
          throw "no id";
        }
        const payload = {
          name: newName,
          account: this.accountId,
        };
        await updateRole(this.accountId, idRole, payload);
        this.actions.showRenameRoleDialog = false;
        this.currentRole = null;
        await this.loadDataSource();
        this.dataSource = this.dataSourceRoles;
      } catch (e) {
        console.log("e: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    async deleteRole() {
      try {
        this.$loader.setAppLoading(true);
        const idRole = this.currentRole.id;
        await deleteReadinessRole(this.accountId, idRole);
        this.actions.deleteRoleDialog = false;
        this.currentRole = null;
        await this.loadDataSource();
        this.dataSource = this.dataSourceRoles;
        const totalItemsAfterDeletion = this.paginationModel.total - 1;
        const totalPages = Math.ceil(
          totalItemsAfterDeletion / this.paginationModel.perPage
        );
        if (this.paginationModel.page > totalPages && totalPages > 0) {
          this.paginationModel.page = totalPages;
        } else if (totalPages === 0) {
          this.paginationModel.page = 1;
        }
        this.paginationModel.total = totalItemsAfterDeletion;
      } catch (e) {
        console.log("e: ", e);
      } finally {
        this.$loader.setAppLoading(false);
      }
    },
    async addResponsibility(name) {
      try {
        const payload = {
          name: name,
          account: this.accountId,
        };
        const newRes = await createResponsibility(this.accountId, payload);
        await this.loadResponsibilities();
        this.dataSource = this.dataSourceRoles;
        return newRes;
      } catch (e) {
        console.log("e: ", e);
      }
    },
    async deActivateRole(){
      this.$loader.setAppLoading(true);
      try {
        const idRole = this.currentRole.id;
        const nameRole = this.currentRole.data.name;
        const payload = {
          name: nameRole,
          status: "inactive",
          account: this.accountId,
        };
        await updateDeActiveRole(this.accountId, idRole, payload);
        this.actions.deActivateRoleDialog = false;
        this.currentRole = null;
        await this.loadDataSource();
        this.dataSource = this.dataSourceRoles;
      } catch (e) {
        console.log("e: ", e);
      }
      this.$loader.setAppLoading(false);
    },
    async reActivateRole() {
      this.$loader.setAppLoading(true);
      try {
        const idRole = this.currentRole.id;
        const nameRole = this.currentRole.data.name;
        const payload = {
          name: nameRole,
          status: "inactive",
          account: this.accountId,
        };
        await updateReActiveRole(this.accountId, idRole, payload);
        this.actions.reActivateRoleDialog = false;
        this.currentRole = null;
        await this.loadDataSource();
        this.dataSource = this.dataSourceRoles;
      } catch (e) {
        console.log("e: ", e);
      }
      this.$loader.setAppLoading(false);
    },
    async loadPositionByID(accountId, posId) {
      const { data } = await getPositionId(accountId, posId);
      if (data) {
        this.currentPosition = data;
        this.positionHeaderState = {
          status: data.status,
          icon: "org_icon_position",
          label: "Position",
          code: data.code ? "#" + data.code : "null",
          name: data.name,
        };
      }
    },
    detachItem(data) {
      this.currentItem = {
        name: data?.name,
        type: data?.type,
        ...data,
      };
      this.parentName = data?.parentName;
      this.actions.showDetachDialog = true;
    },
    detachAction() {
      if (this.currentItem.type === "responsibility") {
        this.detachResponsibilityAction(this.currentItem);
      } else if (this.currentItem.type === "position") {
        this.detachRoleAction(this.currentItem);
      }
      this.actions.showDetachDialog = false;
    },
    async detachRoleAction(data) {
      try {
        const payload = {
          parent_id: data.id,
        };
        await detachRoleAttachment(this.accountId, data.parentId, payload);
        await this.loadDataSource();
      } catch (e) {
        console.error("e: ", e);
      }
    },
    async detachResponsibilityAction(data) {
      try {
        const payload = {
          parent_id: data.parentId,
        };
        await detachAttachments(this.accountId, data.id, payload);
        await this.loadDataSource();
      } catch (e) {
        console.error("e: ", e);
      }
    },
    async detachItems(data, callback) {
      this.$loader.setAppLoading(true);
      this.detachLoader = true;
      const targetObj = data.target;
      const detachObj = data.detachObj;
      try {
        if (targetObj.objectType.toLowerCase() == "role") {
          if (detachObj.type.toLowerCase() == "position") {
            // detach role from position
            const payload = {
              childType: targetObj.objectType.toLowerCase(),
              childId: targetObj.objectId,
              parentData: {
                parent_id: detachObj.id,
              },
            };
            await detachOrgObject(this.accountId, payload);
          } else if (detachObj.type.toLowerCase() == "responsibility") {
            // detach responsibility from role
            const payload = {
              childType: "responsibility",
              childId: detachObj.id,
              parentData: {
                parent_id: targetObj.objectId,
              },
            };
            await detachOrgObject(this.accountId, payload);
          }
        } else if (targetObj.objectType.toLowerCase() == "responsibility") {
          const payload = {
            childType: targetObj.objectType.toLowerCase(),
            childId: targetObj.objectId,
            parentData: {
              parent_id: detachObj.id,
            },
          };
          await detachOrgObject(this.accountId, payload);
        }
        if (data.closeFn) {
          data.closeFn();
        }
        if (callback) {
          callback();
        }
        await this.loadDataSource();
      } catch (e) {
        console.log("e: ", e);
      } finally {
        this.$loader.setAppLoading(false);
        this.detachLoader = false;
      }
    },
  },
};
