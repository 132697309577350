<template>
  <v-container fluid>
    <StandarPageWithList
      title="Employees Readiness"
      :activeNo="activeEmployees"
      :empty="!storeEmployees.length"
    >
      <template #action>
        <Button
          style="display: none"
          :icon="'add-icon.svg'"
          :normal="'normal-active'"
          label="Add Employee 1"
        ></Button>
      </template>
      <template #empty-content>
        <div class="d-flex flex-column align-center">
          <h3 class="mb-2">No employees found</h3>
          <p class="text-center mb-8">
            You need some employees in your <br />
            readiness
          </p>
        </div>
      </template>
      <div class="px-5 mb-5">
        <SearchBar
          @onSearch="onFilter"
          type="button"
          :label="appliedFilters.length > 0 ? 'More Filters' : 'Filters'"
          :length="appliedFilters.length"
          :chipText="appliedFilters"
          ghostText="Search"
          :add="false"
          @onClick="showFilterDialog = true"
          @removeFilter="removeFilter"
          :valueSearch="filterText"
        ></SearchBar>
      </div>
      <DataTable
        :pagination="paginationModel"
        :headers="headers"
        :data="filteredEmployees"
        @onRequest="requestData"
      >
        <template #no-data> </template>
        <template #employee="{ row }">
          <EmployeeCard
            class="my-4"
            :firstName="row.employee.data.first_name"
            :lastName="row.employee.data.last_name"
            :position="row.employee.data.title"
            :photoUrl="row.employee.data.avatar"
            :availability="row.employee.data.availability"
            :nameUrl="`/organization/employees/${row.employee.data.id}/pathtoreadiness`"
            :isAllocated="false"
            :showLinkUnderline="false"
            @update:availability="
              onUpdateAvailability({
                employee: row.employee.data,
                value: $event,
              })
            "
          ></EmployeeCard>
        </template>
        <template #readiness>
          <div class="readiness-width">
            <ReadinessBar :barValue="70" type="readiness" :maxValue="150" />
          </div>
        </template>
        <template #primary="{ row }">
          <div
            v-if="row.employee.data.primary_readiness > 0"
            class="readiness-width"
          >
            <ReadinessBar
              :barValue="row.employee.data.primary_readiness"
              type="readiness"
              :maxValue="150"
            />
          </div>
          <span v-else class="notAssigned">Not Assigned</span>
        </template>
        <template #backedup="{ row }">
          <div
            v-if="row.employee.data.backup_readiness > 0"
            class="readiness-width-backup"
          >
            <ReadinessBar
              :barValue="row.employee.data.backup_readiness"
              type="readiness"
              :maxValue="150"
            />
          </div>
          <span v-else class="notAssigned">Not Assigned</span>
        </template>
        <template #preparing="{ row }">
          <div
            v-if="row.employee.data.preparing_for_readiness > 0"
            class="readiness-width"
          >
            <ReadinessBar
              :barValue="row.employee.data.preparing_for_readiness"
              type="readiness"
              :maxValue="150"
            />
          </div>
          <span v-else class="notAssigned">Not Assigned</span>
        </template>
        <template #actingas="{ row }">
          <div
            v-if="row.employee.data.current_readiness > 0"
            class="readiness-width"
          >
            <ReadinessBar
              :barValue="row.employee.data.current_readiness"
              type="readiness"
              :maxValue="150"
            />
          </div>
          <span v-else class="notAssigned">Not Assigned</span>
        </template>
        <template #actions="{ row }">
          <DropDownMenu
            data-testid="employee-operations-menu"
            @onClick="handleDropdownClick($event, row)"
            :is-open="false"
            :dropdown-items="
              getReadinessEmployeeDropdownItems(
                row.employee.data.current_status
              )
            "
            :translate-x-offset="12"
            :translate-y-offset="30"
          >
            <template #activator>
              <div class="icon-container">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon-svg"
                >
                  <path
                    d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                    stroke="#344054"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </template>
          </DropDownMenu>
        </template>
      </DataTable>
    </StandarPageWithList>
    <TerminateEmployee
      @onAction="terminateEmployee"
      @onClose="onCloseModal"
      :show="showTerminateDialog"
      :info="terminateMessage"
      :avatar="{
        picture: '',
        firstName: `${currentEmployee?.first_name || 'U'}`,
        lastName: `${currentEmployee?.last_name || 'S'}`,
      }"
    />
    <DeleteEmployee
      @onAction="deleteEmployee"
      @onClose="onCloseModal"
      :show="showDeleteDialog"
      :info="deleteMessage"
      :avatar="{
        picture: '',
        firstName: `${currentEmployee?.first_name || 'U'}`,
        lastName: `${currentEmployee?.last_name || 'S'}`,
      }"
    />
    <SearchModal
      :dialog="showFilterDialog"
      @back="showFilterDialog = false"
      @checkedItemsChanged="filterEmployees"
      :clearSelection="clearSelection"
      filterType="Readiness Employee"
      :filterGroups="filterGroups"
      :statusOptions="statusOptions"
      :modalWidth="800"
      :defaultSelected="appliedFilters"
    />
    <NoRecordFound
      v-if="filteredEmployees.length === 0 && storeEmployees?.length != 0"
      :title="title"
      :message="title"
      :subtitle="''"
      class="no-record-found"
      :shouldShowAddAction="false"
      :showClearAction="storeEmployees?.length > 0"
      :clearLabel="clearBtnLabel"
      @onClear="clearSearchFilter()"
      :description="emptyRecordDescription"
    />
  </v-container>
</template>
<script>
import StandarPageWithList from "@components/common/PageHeaderWithButton.vue";
import DataTable from "@components/DataTable/DataTable.vue";
import SearchBar from "@components/SearchBar/SearchBar.vue";
import EmployeeCard from "@components/organizations/employees/EmployeeCard.vue";
import Button from "@components/buttons/Button.vue";
import DropDownMenu from "@components/DropDownMenu/DropDownMenu.vue";
import TerminateEmployee from "@components/organizations/employees/TerminateEmployee.vue";
import DeleteEmployee from "@components/organizations/employees/DeleteEmployee.vue";
import dropdownMixin from "@components/DropDownMenu/dropdownMixin";
import { updateEmployee, terminateEmployee, deleteEmployee } from "@/api";
import SearchModal from "@components/SearchBar/SearchModal.vue";
import ReadinessBar from "@components/ReadinessBar/ReadinessBar.vue";
import { mapState } from "vuex";
import NoRecordFound from "@components/NoRecordFound/NoRecordFound.vue";
import clearStateMixin from "@components/views/mixins/clearStateMixin.js";

export default {
  name: "ReadinessEmployeeView",
  components: {
    SearchBar,
    DataTable,
    EmployeeCard,
    StandarPageWithList,
    Button,
    DropDownMenu,
    TerminateEmployee,
    DeleteEmployee,
    SearchModal,
    ReadinessBar,
    NoRecordFound,
  },
  data() {
    return {
      storeFilterKey: "readinessEmployees",
      showTerminateDialog: false,
      showDeleteDialog: false,
      filterText: "",
      accountID: "",
      employees: [],
      currentEmployee: null,
      paginationModel: {
        page: 1,
        total: 0,
        perPage: 10,
      },
      showFilterDialog: false,
      clearSelection: "",
      appliedFilters: [],
      defaultSelected: [],
      filterGroups: [
        {
          label: "Vacancy",
          items: [
            { label: "No Filter", value: "no_filter" },
            { label: "Vacant", value: "vacant" },
          ],
        },
        {
          label: "Availability",
          items: [
            { label: "No Filter", value: "no_filter" },
            { label: "Availability 0.5 FTE", value: "avail_05_fte" },
            { label: "Availability 1 FTE", value: "avail_1_fte" },
          ],
        },
        {
          label: "Access",
          items: [
            { label: "No Filter", value: "no_filter" },
            { label: "No Access", value: "no_access" },
            { label: "Invited", value: "invited" },
            { label: "Has Access", value: "has_access" },
            { label: "Suspended", value: "suspended" },
            { label: "Blocked", value: "blocked" },
          ],
        },
      ],
      statusOptions: ["draft", "active", "terminated"],
      title: "Employees Readiness",
    };
  },
  mixins: [dropdownMixin, clearStateMixin],
  async mounted() {
    this.accountID = JSON.parse(localStorage.getItem("currentAccountID"));
    this.$loader.show();
    await this.loadEmployees();
    this.$loader.hide();
  },
  computed: {
    ...mapState("employeeStore", {
      storeEmployees: (state) => state.employees,
    }),
    activeEmployees() {
      return this.filteredEmployees.filter(
        (el) => el.employee.data.current_status == "active"
      ).length;
    },
    filteredEmployees: function () {
      let resultArray = [];
      resultArray = this.storeEmployees
        .filter((employee) => employee.current_status === "active")
        .map((el) => {
          const newEl = {
            employee: {
              slot: "employee",
              data: el,
            },
            readiness: {
              slot: "readiness",
            },
            primary: {
              slot: "primary",
            },
            backedup: {
              slot: "backedup",
            },
            preparing: {
              slot: "preparing",
            },
            actingas: {
              slot: "actingas",
            },
            actions: {
              slot: "actions",
              data: {},
            },
          };
          return newEl;
        });
      if (this.filterText && this.filterText.length > 0) {
        resultArray = resultArray.filter((el) => {
          const firstName = el.employee.data.first_name.toUpperCase();
          const lastName = el.employee.data.last_name.toUpperCase();
          const fullName = `${firstName} ${lastName}`;
          const title = el.employee.data.title.toUpperCase();
          const filterTextUpper = this.filterText.toUpperCase();

          return (
            fullName.includes(filterTextUpper) ||
            title.includes(filterTextUpper)
          );
        });
      }
      // filter by status
      const statusFilters = ["draft", "active", "terminated"];
      if (this.appliedFilters.some((val) => statusFilters.includes(val))) {
        resultArray = resultArray.filter((el2) =>
          this.appliedFilters.includes(el2.employee.data.current_status)
        );
      }
      // filter by availability
      const availabilityFilters = [
        "Availability 0.5 FTE",
        "Availability 1 FTE",
      ];
      if (
        this.appliedFilters.some((val) => availabilityFilters.includes(val))
      ) {
        resultArray = resultArray.filter((el2) => {
          return this.appliedFilters.some((el3) => {
            let val = false;
            if (el3 === "Availability 0.5 FTE") {
              val = el2.employee.data.availability === 0.5;
            } else if (el3 === "Availability 1 FTE") {
              val = el2.employee.data.availability === 1;
            }
            return val;
          });
        });
      }
      resultArray = resultArray.sort((a, b) => {
        if (a.employee.data.first_name < b.employee.data.first_name) return -1;
        if (a.employee.data.first_name > b.employee.data.first_name) return 1;
        if (a.employee.data.last_name < b.employee.data.last_name) return -1;
        if (a.employee.data.last_name > b.employee.data.last_name) return 1;
        return 0;
      });
      return resultArray;
    },
    deleteMessage: function () {
      return `${this?.currentEmployee?.first_name || ""} ${
        this?.currentEmployee?.last_name || ""
      } is about to be Deleted. This action is irreversible.`;
    },
    terminateMessage: function () {
      return `${this?.currentEmployee?.first_name || ""} ${
        this?.currentEmployee?.last_name || ""
      } is about to be terminated. We will relieve the employee of all their assignments. We will also suspend access for the employee to this account.`;
    },
  },
  created() {
    this.applySavedFilters();
  },
  methods: {
    applySavedFilters() {
      this.$store.dispatch("filterStore/loadFilters", this.storeFilterKey);
      const savedFilters = this.$store.state.filterStore.filters;
      if (savedFilters && savedFilters.length > 0) {
        this.appliedFilters = savedFilters;
        this.defaultSelected = savedFilters;
      }
    },
    removeFilter(removedFilter) {
      this.$store.dispatch("filterStore/updateFilters", {
        key: this.storeFilterKey,
        filters: this.appliedFilters,
      });
      this.clearSelection = removedFilter;
    },
    filterEmployees(filters) {
      const newArray = filters
        .map((item) => {
          if (item.value !== null) {
            if (typeof item === "string") {
              return item;
            } else if (
              typeof item === "object" &&
              // eslint-disable-next-line no-prototype-builtins
              item.hasOwnProperty("value")
            ) {
              return item.value;
            }
          }
        })
        .filter(Boolean);
      this.appliedFilters = newArray;
      this.$store.dispatch("filterStore/updateFilters", {
        key: this.storeFilterKey,
        filters: this.appliedFilters,
      });
      this.showFilterDialog = false;
    },
    handleDropdownClick(data, row) {
      switch (data.value) {
        case "delete_action":
          this.onDeleteEmployee(row);
          break;
        case "terminate_action":
          this.onTerminateEmployee(row);
          break;
        default:
          break;
      }
    },
    onFilter(data) {
      this.filterText = data;
      if (data) {
        this.paginationModel.page = 1;
      }
    },
    onCloseModal() {
      this.showDeleteDialog = false;
      this.showTerminateDialog = false;
      this.currentEmployee = null;
    },
    async terminateEmployee() {
      try {
        const { id } = this.currentEmployee;
        if (id) {
          await terminateEmployee(this.accountID, id);
        }
        this.showTerminateDialog = false;
        this.currentEmployee = null;
        await this.loadEmployees();
      } catch (e) {
        console.log("error: ", e);
      }
    },
    async deleteEmployee() {
      try {
        const { id } = this.currentEmployee;
        if (id) {
          await deleteEmployee(this.accountID, id);
        }
        this.showDeleteDialog = false;
        this.currentEmployee = null;
        await this.loadEmployees();
      } catch (e) {
        console.log("e: ", e);
      }
    },
    async requestData(data) {
      if (data && data.page) {
        this.paginationModel.page = data.page;
      }
    },
    async loadEmployees() {
      await this.$store.dispatch("employeeStore/loadEmployees");
    },
    async onUpdateAvailability(data) {
      try {
        const { id, first_name, last_name, title } = data.employee;
        const payload = {
          first_name,
          last_name,
          title,
          availability: data.value,
        };
        await updateEmployee(this.accountID, id, payload);
        await this.loadEmployees();
      } catch (e) {
        console.log("error: ", e);
      }
    },
    onTerminateEmployee(row) {
      const { id, first_name, last_name, title } = row.employee.data;
      const employeeObj = {
        id,
        first_name,
        last_name,
        title,
      };
      this.currentEmployee = JSON.parse(JSON.stringify(employeeObj));
      this.showTerminateDialog = true;
    },
    onDeleteEmployee(row) {
      const { id, first_name, last_name, title } = row.employee.data;
      const employeeObj = {
        id,
        first_name,
        last_name,
        title,
      };
      this.currentEmployee = JSON.parse(JSON.stringify(employeeObj));
      this.showDeleteDialog = true;
    },
    clearSearchFilter() {
      if (this.appliedFilters?.length > 0) {
        this.appliedFilters = [];
        this.filterEmployees(this.appliedFilters);
      }

      if (this.filterText?.length > 0) {
        this.filterText = "";
      }
    },
  },
  watch: {
    filteredEmployees(newVal) {
      if (newVal) {
        this.paginationModel.total = newVal.length;
      }
    },
  },
  props: {
    headers: {
      type: Array,
      default() {
        return [
          {
            text: "Employee",
            sortable: true,
            value: "Employee",
          },
          {
            text: "Readiness",
            sortable: false,
            value: "readiness",
          },
          {
            text: "Primary",
            sortable: false,
            value: "primary ",
            img: "icons/briefcase.svg",
          },
          {
            text: "Provides backup to",
            sortable: false,
            value: "backedup",
            img: "icons/briefcaseoutlined.svg",
          },
          {
            text: "Prepares for",
            sortable: false,
            value: "prepares",
            img: "icons/preparing.svg",
          },
          {
            text: "Acting As",
            sortable: false,
            value: "actingas",
            img: "icons/theater.svg",
          },
          {
            text: "",
            sortable: false,
            value: "actions",
          },
        ];
      },
    },
    data: {
      type: Array,
      default() {
        return [
          {
            employee: {
              slot: "employee",
              data: {
                firstName: "Olivia",
                lastName: "Rhye",
                position: "HR Manager",
                photoUrl: "",
                availability: 1,
                isAllocated: false,
              },
            },
            primary: {
              slot: "primary",
              data: {
                type: "no primary assignment",
              },
            },
            backedup: "",
            preparing: "",
            actingas: "",
            actions: {
              slot: "actions",
              data: {},
            },
          },
          {
            employee: {
              slot: "employee",
              data: {
                firstName: "Olivia",
                lastName: "Rhye",
                position: "HR Manager",
                photoUrl: "",
                availability: 1,
                isAllocated: true,
              },
            },
            primary: {
              slot: "primary",
              data: {
                type: "",
                fte: 1,
                photoUrl: "",
              },
            },
            backedup: "",
            preparing: "",
            actingas: "",
            actions: {
              slot: "actions",
              data: {},
            },
          },
          {
            employee: {
              slot: "employee",
              data: {
                firstName: "John",
                lastName: "Doe",
                position: "Software Engineer",
                photoUrl: "https://cdn.vuetifyjs.com/images/john.jpg",
                availability: 0.5,
                isAllocated: false,
              },
            },
            primary: {
              slot: "primary",
              data: {
                type: "no primary assignment",
              },
            },
            backedup: "",
            preparing: "",
            actingas: "",
            actions: {
              slot: "actions",
              data: {},
            },
          },
          {
            employee: {
              slot: "employee",
              data: {
                isAllocated: true,
                availability: 0.5,
                firstName: "John",
                lastName: "Doe",
                position: "Software Engineer",
              },
            },
            primary: {
              slot: "primary",
              data: {
                type: "occupied",
                fte: 1,
                photoUrl: "https://cdn.vuetifyjs.com/images/john.jpg",
              },
            },
            backedup: "",
            preparing: "",
            actingas: "",
            actions: {
              slot: "actions",
              data: {},
            },
          },
          {
            employee: {
              slot: "employee",
              data: {
                isAllocated: true,
                availability: 0.5,
                firstName: "John",
                lastName: "Doe",
                position: "Software Engineer",
              },
            },
            primary: {
              slot: "primary",
              data: {
                type: "vacant",
                fte: 0.5,
                photoUrl: "https://cdn.vuetifyjs.com/images/john.jpg",
              },
            },
            backedup: "",
            preparing: "",
            actingas: "",
            actions: {
              slot: "actions",
              data: {},
            },
          },
          {
            employee: {
              slot: "employee",
              data: {
                isAllocated: true,
                availability: 0.5,
                firstName: "John",
                lastName: "Doe",
                position: "Software Engineer",
              },
            },
            primary: {
              slot: "primary",
              data: {
                type: "blocked",
                fte: 0.5,
              },
            },
            backedup: "",
            preparing: "",
            actingas: "",
            actions: {
              slot: "actions",
              data: {},
            },
          },
          {
            employee: {
              slot: "employee",
              data: {
                isAllocated: true,
                availability: 0.5,
                firstName: "John",
                lastName: "Doe",
                position: "Software Engineer",
              },
            },
            primary: {
              slot: "primary",
              data: {
                type: "not allowed",
                fte: 0.5,
              },
            },
            backedup: "",
            preparing: "",
            actingas: "",
            actions: {
              slot: "actions",
              data: {},
            },
          },
        ];
      },
    },
  },
};
</script>
<style scoped>
.border-card {
  border: 1px solid #e5e5e5;
  padding: 16px;
  border-radius: 8px;
}
.text-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}
.active-badge {
  padding: 4px 12px;
  background-color: #f9f5ff;
  color: #6941c6;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  border-radius: 24px;
  display: inline-flex;
  align-items: center;
}
.employee-view-weight-bold {
  font-weight: 600;
}
.text-empty-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #101828;
}
.text-empty-description {
  color: #475467;
  font-weight: 400;
  line-height: 1.5rem;
}
.notAssigned {
  font-size: 12px;
  color: #354055;
  font-weight: 500;
}
.readiness-width {
  width: 100px;
}
.readiness-width-backup {
  width: 150px;
}

.icon-svg {
  display: block;
}
</style>
