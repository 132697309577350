var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}]},[(_vm.hasSlot('activator'))?_c('div',[_c('div',{ref:"defaultActivator",staticClass:"ex-generic-selector-default-activator",on:{"click":function($event){$event.stopPropagation();return _vm.showHideMenu.apply(null, arguments)}}},[_vm._t("activator")],2)]):_c('div',{ref:"defaultActivator",staticClass:"ex-generic-selector-default-activator",on:{"click":function($event){$event.stopPropagation();return _vm.showHideMenu.apply(null, arguments)}}},[_c('v-btn',{attrs:{"text":""}},[_vm._v("...")])],1),_c('div',{ref:"measureMenu",staticClass:"ex-generic-selector ex-generic-selector-measurement-clone",staticStyle:{"position":"absolute","visibility":"hidden","z-index":"-1000"}},[_c('div',{staticClass:"ex-generic-selector-content-wrapper"},[(!_vm.hideTip)?_c('div',{staticClass:"tip",class:_vm.tipClasses}):_vm._e(),_c('div',{staticClass:"ex-generic-selector-content-container",class:_vm.containerClasses},[(_vm.hasSlot('content'))?_c('div',{staticClass:"content"},[_vm._t("content")],2):_c('div',{staticClass:"pa-2 content"},[_vm._v("Content")])])])]),_c('v-menu',{attrs:{"top":_vm.displayTop,"transition":`${_vm.customLeftTransition ? 'scale-transition' : null}`,"value":_vm.value,"close-on-content-click":!_vm.persistent,"close-on-click":!_vm.persistent,"content-class":`ex-generic-selector ${_vm.customLeftTransition}`,"position-x":_vm.xPosition,"position-y":_vm.yPosition,"eager":"","attach":_vm.attachSelectorValue && _vm.attachSelectorValue.length > 0 ? _vm.attachSelectorValue : false,"absolute":_vm.absolute}},[_c('div',{staticClass:"ex-generic-selector-content-wrapper"},[(!_vm.hideTip)?_c('div',{staticClass:"tip",class:{
        'left': this.tipSide == 'left',
        'right': this.tipSide == 'right',
        'shadow': this.variant == 'shadow',
        'border': this.variant == 'border',
        'displayTop': this.displayTop,
      }}):_vm._e(),_c('div',{staticClass:"ex-generic-selector-content-container",class:{
        'hideTip': this.hideTip,
        'left': this.tipSide == 'left',
        'right': this.tipSide == 'right',
        'shadow': this.variant == 'shadow',
        'border': this.variant == 'border',
        'displayTop': this.displayTop
      }},[(_vm.hasSlot('content'))?_c('div',{staticClass:"content"},[_vm._t("content")],2):_c('div',{staticClass:"pa-2 content"},[_vm._v("Content")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }