<template>
  <div>
    <h1>My Stuff</h1>
  </div>
</template>
<script>
export default {
  name: "MyStuffView",
  async mounted() {
    await this.loadAppCount();
  },
  methods: {
    async loadAppCount() {
      await this.$store.dispatch("employeeStore/loadEmployees");
      await this.$store.dispatch("positionStore/loadPositions");
    },
  },
};
</script>
