var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-chip',{staticClass:"roles-badge",class:{
    'roles-badge-red': _vm.count === 0,
    'roles-badge-black': _vm.count > 0
  },attrs:{"label":""}},[_c('Icon',{staticClass:"roles-icon",attrs:{"name":_vm.icon}}),_c('span',{staticClass:"roles-label",class:{
      'count-label-red': _vm.count === 0,
      'count-label-black': _vm.count > 0
    }},[_vm._v(_vm._s(_vm.count))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }